.App {
  text-align: center;
  background-color: #2b2b2b;
  font-family: "Inter", sans-serif;
  display: flex;
  min-height: 100vh;
}

.your-address {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  text-align: left;
}

.address {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #9c9c9c;
  text-align: left;
}

.header-panel {
  justify-content: space-between;
  display: flex;
  margin-bottom: 30px;
}

.sidebar {
  background-color: #202020;
  font-family: "Inter", sans-serif;
  padding: 30px;
  width: 240px;
}

.logo {
  width: 36px;
  height: 36px;
  padding-right: 10px;
}

.logo-font {
  font-weight: 600;
  font-size: 24px;
  color: white;
  padding-top: 9px;
}

hr {
  border: 1px solid #2b2b2b;
  margin-top: 20px;
  margin-bottom: 20px;
}

.main {
  padding: 25px;
  width: calc(100% - 244px);
}

.main-body {
  height: 95%;
  display: flex;
  justify-content: space-between;
}

.connect-button {
  background-color: #00cc33;
  width: 120px;
  height: 36px;
  border-radius: 6px;
  border: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  float: right;
}

.connect-button:active {
  background-color: #00b12c;
  width: 120px;
  height: 36px;
  border-radius: 6px;
}

.disconnect-button {
  background-color: #202020;
  width: 120px;
  height: 36px;
  border-radius: 6px;
  border: none;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;
  float: right;
}

.disconnect-button:active {
  background-color: #111111;
  width: 120px;
  height: 36px;
  border-radius: 6px;
}

.card {
  border-radius: 12px;
  background: #202020;
  padding: 28px 35px 32px 30px;
  text-align: left;
  position: relative;
  height: 90%;
  width: 85%;
  margin-right: 24px;
  min-width: 450px;
}

.loading {
  padding-bottom: 20px;
  padding-left: 85px;
  position: relative;
}

.loading-symbol {
  padding-left: 75px;
  padding-top: 68px;
  position: absolute;
  height: 60px;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.card-sub {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  color: #9c9c9c;
  padding-top: 10px;
}

.klima-button {
  background-color: transparent;
  border: none;
  padding-bottom: 10px;
  display: flex;
}

.card-symbol {
  margin-right: 11px;
  height: 30px;
}

.conversion {
  background-color: #2b2b2b;
  height: 60px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  padding-right: 10px;
  display: flex;
  font-size: 17px;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 8px;
  align-items: center;
  padding-left: 7px;
}

.arrow {
  padding-top: 17px;
  font-size: 30px;
  position: relative;
  user-select: none;
}

.arrow::before,
.arrow::after {
  --scale: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) scale(var(--scale));
  transition: 100ms transform;
}

.arrow:before {
  content: attr(data-tooltip);
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  width: max-content;
  background: #333;
  font-size: 12px;
}

.arrow:hover::before {
  --scale: 1;
}

.burn {
  background-color: #00cc33;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.burn:active {
  background-color: #01a52a;
}

* {
  font-family: "Inter", sans-serif;
  color: white;
}

input[type="text"],
textarea {
  width: 100%;
  background-color: #202020;
  border-radius: 10px;
  height: 50px;
  border: 2px solid #2f2f2f;
  margin-top: 6px;
  box-sizing: border-box;
  padding-left: 20px;
}

input[type="number"],
textarea {
  width: 85%;
  background-color: #202020;
  border-radius: 10px 0 0 10px;
  height: 50px;
  border: 2px solid #2f2f2f;
  margin-top: 6px;
  padding-left: 20px;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-title {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #9c9c9c;
}

.max {
  background-color: #00cc33;
  height: 52px;
  width: 15%;
  border-radius: 1px 10px 10px 1px;
  border: none;
}

.max:active {
  background-color: #01a52a;
}

.coin-selection {
  background-color: #2b2b2b;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  display: flex;
  font-size: 17px;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 8px;
  align-items: center;
  padding-right: 12px;
}

.coin-selection:active {
  background-color: #252525;
}

.burn-panel {
  position: absolute;
  top: calc(50% - 305px);
  left: calc(50% - 32.5%);
  width: 65%;
}

.mini-card {
  border-radius: 12px;
  background: #202020;
  padding: 28px 35px 32px 30px;
  margin-bottom: 24px;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
}

.breakdown-card {
  border-radius: 12px;
  background: #202020;
  padding: 28px 35px 32px 30px;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
}

.big-number {
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  margin: 20px 0 0 0;
}

.bct-burned {
  color: #9c9c9c;
}

.modal-background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  width: 400px;
  height: fit-content;
  border-radius: 12px;
  background-color: #303030;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: flex-start;
  display: flex;
  animation: enter 200ms ease-in-out;
  animation-fill-mode: forwards;
}

.modal-header {
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@keyframes enter {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes exit {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

.modal-close {
  background-color: #202020;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  text-align: center;
}

.coin-box {
  width: 48px;
  height: 48px;
  padding-right: 7px;
}

.info-pair {
  width: fit-content;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.select-coin-button {
  width: 400px;
  height: 65px;
  background-color: #303030;
  border-radius: 8px;
  border: none;
  margin-bottom: 16px;
  padding-left: 10px;
  justify-content: flex-start;
  display: flex;
  padding-top: 8px;
}

.circle-logo {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}

.coin-name {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 45px;
}

.coin-balance {
  font-weight: 500;
  font-size: 12px;
  color: #9c9c9c;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 45px;
}

.coin-details {
  margin-left: 12px;
  width: 80%;
  display: flex;
  justify-content: space-between;
}

@keyframes scaledown {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleup {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.04);
  }
}

button {
  cursor: pointer;
}

button:hover {
  filter: brightness(0.9);
}

#btnControl:checked + label > img {
  align-items: flex-start;
  display: flex;
  animation: exit 200ms ease-in-out;
}

.approving {
  animation: spin 1s linear infinite;
  height: 200px;
  width: 200px;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.approve-outcome {
  height: 200px;
  width: 200px;
}

.toast {
  position: fixed;
  left: calc(50% - 225px);
  width: 450px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #ff0033;
  animation: fadeDown 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.card-stacks {
  flex-direction: column;
  display: flex;
  width: 30%;
}

.seperate {
  display: flex;
  justify-content: space-between;
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    top: -50%;
  }

  100% {
    opacity: 1;
    top: 2%;
  }
}

@media (max-width: 1200px) {
  .App {
    text-align: center;
    background-color: #2b2b2b;
    font-family: "Inter", sans-serif;
    width: auto;
    min-height: none;
    height: none;
  }
  .main {
    padding: 1%;
    align-items: center;
    width: calc(100% - 25px);
  }
  .main-body {
    height: 95%;
    display: block;
    justify-content: normal;
  }
  .mini-card {
    width: calc(100% - 50px);
  }
  .card-stacks {
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .card {
    height: 850px;
    width: calc(100% - 50px);
    margin-bottom: 30px;
    min-width: 0;
  }
  .breakdown-card {
    width: calc(100% - 50px);
    margin-bottom: 30px;
  }
  .burn-panel {
    left: 10%;
    width: 80%;
    max-width: none;
  }
  .modal-container {
    width: 80vw;
    max-width: 400px;
  }
  .select-coin-button {
    width: 80vw;
    max-width: 400px;
  }
}
